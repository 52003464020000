.page {
	width: 776px;
	margin: 0 auto;
	min-height: 100vh;
	padding-bottom: 20px;
	background-color: #fff;
}
.header {
	padding: 40px 0 16px;
	line-height: 45px;
}
.logo {
	display: inline-block;
	height: 40px;
	text-decoration: none;
}
.logo img {
	display: inline-block;
	max-height: 100%;
	vertical-align: top;
}
.header span {
	margin-left: 10px;
	font-size: 18px;
	line-height: 26px;
	font-weight: 500;
}
.body {
	width: 100%;
}