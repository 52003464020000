.container {
	width: 100%;
}
.userinfoWrap {
	border-radius: 16px 16px 0 0;
	background-color: var(--primary-color);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 24px 10px 40px;
	color: #fff;
	font-size: 12px;
}
.userinfo {
	display: block;
}
.link {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.balance {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 20px;
	margin-top: 10px;
}
.tokens,
.points {
	width: 80px;
	text-align: center;
}
.label {
	color: rgba(255, 255, 255, 0.65);
	line-height: 18px;
}

.main {
	width: 100%;
}
.content {
	border-radius: 0 0 16px 16px;
}
.content {
	width: 100%;
	background-Color: var(--bg-color-default);
	padding: 24px 40px 48px;
}
.desc {
	font-size: 12px;
	line-height: 1.4;
	color: var(--font-color-grey);
	margin-bottom: 16px;
}
.productList {
	display: flex;
	flex-wrap: wrap;
	margin-left: -12px;
	margin-right: -12px;
}
.productItem {
	position: relative;
	width: 156px;
	height: 124px;
	margin: 0 12px 12px;
	border: 1px solid transparent;
	border-radius: 8px;
	text-align: center;
	cursor: pointer;
	overflow: hidden;
}
.productItem:global(.active) {
	border-color: var(--primary-color);
}
.productBody {
	background: #fff;
	padding: 24px 10px 15px;
}
.productTitle {
	display: flex;
	height: 24px;
	line-height: 24px;
	vertical-align: top;
	color: var(--font-color-default);
	justify-content: center;
	align-items: center;
}
.productTitle strong {
	font-size: 18px;
	font-weight: 600;
	margin-right: 4px;
}
.productsGift {
	display: flex;
	margin-top: 4px;
	height: 16.8px;
	line-height: 16.8px;
	vertical-align: top;
	font-size: 12px;
	font-weight: 400;
	color: var(--font-color-normal);
	justify-content: center;
	align-items: center;
}
.productFooter {
	height: 40px;
	line-height: 40px;
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.08);
}
.productItem:global(.active) .productFooter {
	background-color: rgba(19, 197, 206, 0.4);
}
.productPrice {
	font-size: 14px;
	line-height: 20px;
	height: 20px;
	font-weight: 600;
	color: var(--font-color-default);
}
.productCorner {
	position: absolute;
	top: -2px;
	right: -2px;
	z-index: 2;
	background-color: var(--primary-color);
	height: 22px;
	line-height: 22px;
	padding: 0 5px;
	border-radius: 0 8px 0 4px;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
}
.productHot {
	display: inline-block;
	margin-right: 3px;
}

.methodBox {
	margin-top: 20px;
}
.methodBox .methodTitle {
	font-size: 16px;
	line-height: 1.5;
	font-weight: 500;
	color: var(--font-color-default);
	margin-bottom: 16px;
}
.methodList {
	display: flex;
	flex-wrap: wrap;
	margin-left: -12px;
	margin-right: -12px;
}
.methodItem {
	display: flex;
	width: 88px;
	height: 64px;
	background: #fff;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	margin: 0 12px 12px;
	border: 1px solid transparent;
}
.methodItem:global(.active) {
	border-color: var(--primary-color);
}

.settleBox {
	margin-top: 20px;
	width: 100%;
	background: #fff;
	border-radius: 24px;
	padding: 24px 32px 24px 40px;
}
.settleContent {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.settleInfo {
	flex: 1;
}
.settlePrice {
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
	color: var(--primary-color);
	margin-bottom: 8px;
}
.settleText {
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
}
.settleBox .settleBtn {
	width: 144px;
	height: 48px;
	border-radius: 24px;
	font-size: 16px;
	font-weight: 500;
}