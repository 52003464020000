.header {
	position: sticky;
	top: 0;
	height: 80px;
	line-height: 48px;
	padding: 16px 0 15px;
	border-bottom: 1px solid var(--border-color-default);
	background: #fff;
	z-index: 999;
}
.logo {
	height: 40px;
	margin-top: 4px;
	display: inline-block;
	text-decoration: none;
}
.logo img {
	display: inline-block;
	max-height: 100%;
	vertical-align: top;
}
.nav {
	flex: 1;
	margin: 0 1rem;
}
.nav li {
	display: inline-block;
	font-weight: 500;
}
.nav li a {
	font-size: 1rem;
	line-height: 3;
	display: block;
	text-decoration: none;
	padding: 0 1rem;
	font-weight: 500;
	border-radius: 5px;
}
/* .nav li a:global(.active), */
.nav li a:hover {
	background: rgba(0, 0, 0, 0.06);
}
.nav li svg {
	display: inline-block;
	margin-top: 0.75rem;
	margin-right: 4px;
	vertical-align: top;
}
.right {
	float: right;
}
.login,
.signup {
	display: inline-block;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	cursor: pointer;
	padding: 12px 16px;
	border-radius: 5px;
}
.login:hover, 
.signup:hover {
	background: rgba(0, 0, 0, 0.06);
}

.switchLang {
	border-radius: 5px;
}
.switchLang:hover {
	background: rgba(0, 0, 0, 0.06);
}
.user {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	padding: 8px 16px;
	border-radius: 5px;
}
.user:hover {
	background: rgba(0, 0, 0, 0.06);
}