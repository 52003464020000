.storyHeader {
	width: 100%;
	background: #F5F7F8;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	justify-content: center;
}
.storyHeaderInner {
	display: flex;
	justify-content: center;
	padding: 0 100px;
}
.storyCover {
	position: relative;
	width: 216px;
	height: 288px;
	min-width: 216px;
	-webkit-filter: drop-shadow(0 8px 12px rgba(18, 18, 18, .16));
	filter: drop-shadow(0 8px 12px rgba(18, 18, 18, .16));
}
.storyCover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.medal {
	position: absolute;
	top: 10px;
	left: 10px;
	display: inline-block;
	background: #EB5273;
	height: 28px;
	line-height: 28px;
	padding: 0 10px;
	z-index: 2;
	border-radius: 4px;
	color: #fff;
}
.medalIcon {
	display: inline-block;
	vertical-align: top;
	margin-top: 6px;
	margin-right: 5px;
}
.medalNum {
	font-size: 16px;
	letter-spacing: 0;
	font-weight: 600;
	margin-right: 5px;
}
.medalText {
	font-size: 12px;
}
.storyInfo {
	min-width: 0;
	margin: 0 0 0 32px;
	padding-bottom: 24px;
	padding-top: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
h2.storyTitle {
	white-space: nowrap;
	overflow : hidden;
	text-overflow: ellipsis;
	height: 40px;
	line-height: 40px;
	font-size: 30px;
	font-weight: 500;
}
.storyChips {
	display: flex;
	flex-direction: row;
	margin-top: 16px;
	margin-bottom: 16px;
}
.storyStat {
	font-size: 16px;
	line-height: 24px;
	display: flex;
}
.storyStat svg {
	display: inline-block;
	vertical-align: top;
	margin-right: 5px;
}
.readsCount {
	text-align: left;
}
.partsCount {
	margin-left: 32px;
}
.storyStatLabel {
	color: var(--font-color-grey);
	line-height: 24px;
}
.storyStatValue {
	margin-left: 16px;
	color: var(--font-color-default);
}
.storyActions  {
	margin-top: 40px;
}
.storyActions .startReading,
.storyActions .addTo {
	height: 40px;
	line-height: 40px;
	border-radius: 20px;
	padding-left: 24px;
	padding-right: 24px;
	font-size: 16px;
	font-weight: 500;
	box-shadow: none;
}
.storyActions .addTo {
	margin-left: 16px;
}

.storyBody {
	padding-top: 30px!important;
}
.storyDesc {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
}
.storyCopyright {
	margin-top: 24px;
}
.storyTags {
	margin-top: 20px;
}
.storyTags :global(.MuiChip-root) {
	margin-right: 15px;
}