.header:global(.MuiDialogTitle-root) {
  padding: 10px 15px 10px 8px;
  position: sticky;
  width: 100%;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}
.header .back {
  min-width: auto;
  text-align: center;
}

.meta {
  color: #222;
  flex: 1;
}
.title1 {
  color: #6f6f6f;
  font-size: 14px;
  line-height: 18px;
}
.title2 {
  font-size: 21px;
  font-weight: 600;
  line-height: 24px;
}
.actions .btn {
  margin-left: 15px;
}