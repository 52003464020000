.header {
  height: 80px;
  position: sticky;
  z-index: 2;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.inner {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header .back {
  min-width: auto;
  text-align: center;
}

.meta {
  display: flex;
  color: #222;
  font-size: 14px;
  color: var(--font-color-grey);
  line-height: 24px;
  cursor: pointer;
  max-width: 240px;
}
.center {
  flex: 1;
  margin: 0 16px;
  text-align: center;
  min-width: 0;
}
.header .title {
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
}
.actions .btn {
  margin-left: 15px;
}
.actions .more {
  margin-left: 10px;
  min-width: 40px;
}