.loginContainer {
	width: 100vw;
	min-height: 100vh;
	background: url('@/assets/images/bg.png') #F2F7FF;
	background-size: cover;
	background-position: center;
}
.wrap {
	padding-top: 80px;
	padding-bottom: 50px;
}
.content {
	width: 520px;
	border-radius: 5px;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0px 2px 24px  rgba(200, 201, 204, 0.5);
	margin: 0 auto;
	padding: 0 40px 40px;
}