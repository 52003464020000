.list {
	width: 224px;
}
.listItem {
	position: relative;
	display: inline-block;
	line-height: 36px;
	margin-bottom: 16px;
	font-size: 16px;
	font-weight: 400;
}
.listItem:global(.active) {
	font-weight: 500;
}

.listItem:global(.active)::before {
	content: "";
	position: absolute;
	right: 100%;
	top: 50%;
	height: 16px;
	width: 3px;
	background-color: var(--primary-color);
	transform: translateX(-7px) translateY(-50%);
}