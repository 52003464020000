.header {
  padding: 18px 0;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header .back {
  min-width: auto;
  text-align: center;
}
.meta {
  color: #222;
  font-size: 14px;
  color: var(--font-color-grey);
  line-height: 20px;
  cursor: pointer;
}
.header .title {
  flex: 1;
  margin: 0 16px;
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}
.actions .btn {
  margin-left: 24px;
}