.cover {
	position: relative;
	display: block;
	width: 252px;
	height: 336px;
	text-align: center;
}
.cover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
	pointer-events: none;
	user-select: none;
	border-radius: 5px;
}
.label {
	display: block;
	width: 100%;
	height: 100%;
}