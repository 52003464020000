.commentCard {
	display: flex;
	flex-direction: row;
	padding-top: 16px;
	gap: 16px;
}
.commentContent {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	flex-direction: column;
	padding-bottom: 16px;
	padding-right: 10px;
	border-bottom: 1px solid #e7e7e7;
	gap: 8px;
}
.commentChild .commentContent {
	padding-bottom: 0;
	padding-right: 0;
	border-bottom: none;
}
.commentCard .commentUser {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}
.commentText {
	font-size: 16px;
	line-height: 24px;
	white-space: pre-wrap;
	word-wrap: break-word;
}
.commentReplyUser {
	color: #155BD4;
	margin-right: 4px;
}
.commentMeta {
	display: flex;
	flex-direction: row;
	gap: 8px;
	line-height: 22px;
}
.commentTime {
	color: var( --grey-color);
	font-size: 14px;
}
.replyButton {
	font-size: 14px;
	font-weight: 400;
	color: var(--primary-color);
	cursor: pointer;
	outline: none;
}
.deleteButton {
	cursor: pointer;
	outline: none;
}