.list {
	padding-top: 0;
	width: 100%;
}
.listItem {
	display: flex;
	padding: 1rem 2rem;
	border-bottom: 1px solid var(--border-color-default);
}
.listItem:last-child {
	border-bottom: none;
}
.listItemL {
	flex: 1;
	margin-right: 30px;
	min-width: 0;
}
.listItemR {
	display: flex;
	align-items: center;
}
.partLink {
	display: inline-block;
	margin-bottom: 8px;
	max-width: 100%;
}
.storyParts .partTitle {
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.partStatus {
	line-height: 20px;
	margin-right: 1rem;
	color: var(--font-color-grey);
}
.partStatus .published {
	color: var(--primary-color);
}