.header {
	padding: 8px 16px 20px 0;
	border-bottom: 1px solid var(--border-color-default);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.sorter {
	-webkit-transition: -webkit-transform .2s;
	transition: -webkit-transform .2s;
	transition: transform .2s;
	transition: transform .2s, -webkit-transform .2s;
	cursor: pointer;
}
.sorter:global(.desc) {
	-webkit-transform: scaleY(-1) translateY(-3px);
	transform: scaleY(-1) translateY(-3px);
}
.list {
	margin-left: -8px;
	margin-right: -8px;
}
.item {
	float: left;
	width: 50%;
	padding-left: 8px;
	padding-right: 8px;
}
.itemInner {
	padding: 13px 16px 7px;
	border-bottom: 1px solid var(--border-color-default);
	display: flex;
	flex-direction: row;
	align-items: center;
}
.itemIndex {
	font-size: 16px;
	font-weight: 400;
	margin-right: 24px;
}
.itemContent {
	flex: 1;
	min-width: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
}
.buttonWrap {
	margin: 40px auto;
	text-align: center;
}
.buttonWrap .loginButton {
	height: 48px;
	padding-left: 48px;
	padding-right: 48px;
	border-radius: 24px;
	font-size: 16px;
	font-weight: 500;
}