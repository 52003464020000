.commentList {
	background: #fff;
	position: relative;
	height: 100%;
	padding-top: 75px;
	padding-bottom: 80px;
}
.commentListHead {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 32px 24px 20px;
}
.close {
	position: absolute;
	right: 24px;
	top: 50%;
	margin-top: -24px;
	cursor: pointer;
}
.commentListBody {
	padding: 0 24px;
	height: 100%;
	overflow-y: auto;
}
.commentCount {
	line-height: 20px;
	margin-left: 16px;
	color: var(--font-color-grey);
}
.commentItem {
	position: relative;
}
.expandButton {
	font-size: 14px;
	color: var(--primary-color);
	margin-right: 7px;
	cursor: pointer;
	outline: none;
}
.commentListFoot {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	background: #fff;
	box-shadow: 0px -2px 4px  rgba(0, 0, 0, 0.1);
}