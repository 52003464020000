.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 30px;
}
.header h2 {
	flex: 1;
	max-width: 80%;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 0;
}
.empty {
	padding: 40px;
	text-align: center;
}
.empty p {
	font-size: 30px;
	line-height: 80px;
}
.content {
	border: 1px solid var(--border-color-default);
	border-radius: 5px;
}
.tabs {
	padding-left: 10px;
	border-bottom: 1px solid var(--border-color-default);
}
.storyContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	align-content: flex-start;
	cursor: pointer;
	padding: 16px 23px;
}
.leftContainer {
	flex: 2;
	display: flex;
	flex-direction: row;
	min-width: 0;
}
.storyCover {
	width: 90px;
	height: 120px;
	margin-right: 16px;
}
.storyCover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 5px;
}
.storyInfo {
	flex: 1;
	min-width: 0;
}
.storyContainer .storyTitle {
	font-size: 18px;
	line-height: 26px;
	font-weight: 500;
	height: 26px;
	margin: 3px 0 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-bottom: 3px;
	color: var(--font-color-default);
	text-decoration: none;
}
.storyTitle:hover {
	text-decoration: underline;
}
.storyStatus {
	line-height: 20px;
	margin-bottom: 8px;
	color: var(--font-color-grey);
}
.storyStatus .published {
	color: var(--primary-color);
}
.storyTime {
	color: var(--font-color-lightgrey);
	line-height: 20px;
	font-size: 14px;
	margin-bottom: 8px;
}