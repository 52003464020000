.spinner__wrap {
	position: relative;
}
.spinner {
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	z-index: 4;
	display: block;
	width: 100%;
	height: 100%;
	max-height: 400px;
}
.spinner__inner {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	justify-content: center;
	align-items: center;
}
.spinner__static {
	position: static;
	padding: 16px 0;
}
.spinner__static .spinner__inner {
	position: static;
	transform: none;
	height: 100%;
}
.spinner__icon {
	animation: 0.6s linear 0s infinite normal forwards running spinner;
}
.spinner__tip {
	line-height: 30px;
	color: var(--primary-color);
}
.spinner__horizontal {
	flex-direction: row;
}
.spinner__horizontal .spinner__tip {
	margin-left: 16px;
}
.spinner__content {
	position: relative;
	transition: opacity 0.3s;
}
.spinner__blur {
	clear: both;
	opacity: 0.5;
	user-select: none;
	pointer-events: none;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}