.page {
	display: flex;
	flex-direction: column;
	min-height: calc(100% + 32px);
}

/* header */
.header {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: inherit;
	border-bottom: 1px solid var(--border-color-default);
}
.header .headerContet {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0;
	padding: 12px 0 11px;
}
.headerLeft {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.logo {
	height: 40px;
}
.logo img {
	display: inline-block;
	max-height: 100%;
	vertical-align: top;
}
.breadcrumb {
	margin-left: 26px;
	display: flex;
}
.headerLeft .storyTitle,
.headerLeft .partTitle {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	max-width: 300px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.headerLeft .partTitle + .partTitle {
	margin-left: 5px;
}
.headerLeft .readProcess {
	font-size: 16px;
	line-height: 24px;
	color: var(--font-color-grey);
}
.headerRight {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 32px;
}
.link {
	color: var(--font-color-grey);
}
.headerRight .addTo {
	height: 32px;
	line-height: 32px;
	border-radius: 16px;
	padding-left: 16px;
	padding-right: 16px;
	font-size: 14px;
	font-weight: 400;
	box-shadow: none;
}
.login,
.signup {
	display: inline-block;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	cursor: pointer;
	padding: 3px 16px;
	border-radius: 5px;
}
.login:hover, 
.signup:hover {
	background: rgba(0, 0, 0, 0.06);
}

/* content */
.body {
	width: 100%;
	flex: 1;
}
.main {
	width: 1000px;
	margin: 0 auto;
	padding: 96px 0;
}
.main .title {
	font-size: 24px;
	line-height: 35px;
	font-weight: 500;
	margin: 0 auto;
	text-align: center;
	padding: 0 100px;
}
.content {
	margin: 40px 100px 0;
	white-space: pre-wrap;
	text-align: justify;
}
.content .contentItem {
	line-height: 1.45;
	letter-spacing: 0;
	font-weight: 400;
	margin-bottom: 24px;
	word-wrap: break-word;
	word-break: break-word;
}

/* mark */
.maskWrap {
	position: relative;
}
.mask {
	position: absolute;
	top: -120px;
	left: 0;
	height: 120px;
	width: 100%;
}

/* payment */
.paymentBox {
	padding: 12vh 100px 64px;
	text-align: center;
}
.paymentBox .paymentTitle {
	font-weight: 500;
	margin-bottom: 24px;
}
.paymentBox .paymentDesc {
	width: 300px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	filter: opacity(0.6);
}
.paymentBox .paymentBtn {
	min-width: 388px;
	height: 56px;
	line-height: 56px;
	border-radius: 28px;
	font-size: 16px;
	font-weight: 500;
	box-shadow: none;
}
.paymentBox .autoLock {
	margin-top: 24px;
	font-size: 12px;
	line-height: 16px;
	filter: opacity(0.6);
	padding: 0 30px;
	cursor: pointer;
}
.paymentBox .autoLockIcon {
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-right: 8px;
	border-width: 1px;
	vertical-align: top;
	border-style: solid;
	border-color: currentColor;
	border-radius: 50%;
}
.paymentBox .autoLockIcon:global(.selected)::after {
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 8px;
	height: 8px;
	background: currentColor;
	border-radius: 50%;
}

/* loginBox */
.loginBox {
	padding: 120px 100px 200px;
	text-align: center;
}
.loginBox .loginTitle {
	font-weight: 500;
	margin-bottom: 24px;
}
.loginBox .loginDesc {
	width: 300px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	filter: opacity(0.6);
}
.loginBox .loginBtn {
	width: 388px;
	height: 56px;
	line-height: 56px;
	border-radius: 28px;
	font-size: 16px;
	font-weight: 500;
	box-shadow: none;
}

/* stat */
.statWrap {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 40px;
	margin-bottom: 40px;
	padding: 0 100px;
}
.readStat,
.commentStat {
	flex: 1;
	margin: 0 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.commentStat  {
	cursor: pointer;
}
.statWrap .statLabel {
	line-height: 24px;
	margin-top: 8px;
	font-size: 16px;
}
.statWrap  .statNum {
	font-size: 14px;
	line-height: 20px;
	color: var(--font-color-grey);
	margin-top: 4px;
}

/* pagination */
.pagination {
	display: flex;
	flex-direction: row;
	/* margin-top: 40px; */
	padding: 0 100px;
	margin-left: -12px;
	margin-right: -12px;
}
.pagination :global(.MuiButton-root) {
	margin: 0 12px;
	flex: 1;
	font-size: 16px;
	box-shadow: none;
	background: rgba(0, 0, 0, 0.08);
	color: inherit;
}
.pagination :global(.MuiButton-root):hover {
	background: rgba(0, 0, 0, 0.12);
	box-shadow: none;
}

/* actions */
.actionsWrap {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translateY(-50%);
	margin-left: 524px;
}
.action {
	display: flex;
	width: 48px;
	height: 48px;
	border-radius: 24px;
	margin-top: 24px;
	background: #fff;
	align-items: center;
	justify-content: center;
	box-shadow: 0 2px 24px rgba(200, 201, 204, 0.5);
	cursor: pointer;
}
.action:global(.active) {
	color: var(--primary-color);
}
.action:first-child {
	margin-top: 0;
}
.likeActive .activeIcon,
.bookmarkActive .activeIcon,
.likeActive:global(.active) .icon,
.bookmarkActive:global(.active) .icon {
	display: none;
}
.likeActive:global(.active) .activeIcon,
.bookmarkActive:global(.active) .activeIcon {
	display: block;
}
.themeCircle {
	display: none;
	border-radius: 50%;
	margin: 0 8px;
	width: 0px;
	height: 20px;
	border: 2px solid var(--border-color-default);
	flex-shrink: 0;
	transition: all 0.2s linear;
}
.themeCircle:global(.active) {
	display: block;
	width: 20px;
	border-color: var(--bg-color-black);
}
.themeAction:global(.open) .themeCircle:global(.active) {
	border-color: var(--primary-color);
}
.themeAction {
	padding: 6px;
	transition: width 0.2s linear;
	justify-content: flex-start;
}
.themeAction:global(.open){
	width: 156px;
}
.themeAction:global(.open) .themeCircle {
	display: block;
	width: 20px;
}
.fontSizeAction {
	transition: width 0.2s linear;
}
.fontSizeAction:global(.open) {
	width: 192px;
}
.fsCircleWrap {
	width: 0;
	display: none;
	flex-direction: row;
	align-items: center;
	overflow: hidden;
	animation: hideFsCircle 0.2s linear;
}
.fontSizeAction:global(.open) .fsCircleWrap {
	display: flex;
	width: 120px;
	animation: showFsCircle 0.2s linear;
}
.iconRight {
	width: 0px;
	display: block;
	transition: width 0.2s linear;
}
.fontSizeAction:global(.open) .iconRight {
	width: 24px;
}
.fsCircle {
	position: relative;
	width: 12px;
	height: 12px;
	margin-left: 4px;
	margin-right: 5px;
	border: 3px solid #fff;
	background: var(--lightgrey-color);
	font-size: 0;
	border-radius: 50%;
}
.fsCircle:after {
	display: none;
	position: absolute;
	content: "";
	display: block;
	width: 15px;
	height: 1px;
	left: 100%;
	top: 50%;
	margin-top: -0.5px;
	background: var(--lightgrey-color);
	z-index: 2;
}
.fontSizeAction:global(.open)  .fsCircle:after {
	display: block;
}
.fsCircle:first-child {
	margin-left: 0;
}
.fsCircle:last-child {
	margin-right: 0;
}
.fsCircle:last-child:after  {
	display: none!important;
	margin-right: 0;
}
.fsCircle:global(.active) {
	width: 12px;
	height: 12px;
	background: #fff;
	border: 1px solid var(--primary-color);
	margin-left: 6px;
}

/* theme white */
[theme="white"].page {
	background: #F6F7F9;
}
[theme="white"] .main,
[theme="white"] .action {
	background: #fff;
}
[theme="white"] .mask {
	background: -webkit-gradient(linear,left top, left bottom,from(hsla(0,0%,100%,0)),color-stop(88.32%, #fff));
	background: linear-gradient(180deg,hsla(0,0%,100%,0),#fff 88.32%);
}

[theme="beige"].page {
	background: #F3EAD0;
}
[theme="beige"] .main,
[theme="beige"] .action {
	background: #F5F0DD;
}
[theme="beige"] .mask {
	background: -webkit-gradient(linear,left top, left bottom,from(hsla(0,0%,100%,0)),color-stop(88.32%, #F5F0DD));
	background: linear-gradient(180deg,hsla(0,0%,100%,0),#F5F0DD 88.32%);
}

[theme="gray"].page {
	background: #E0E0E0;
}
[theme="gray"] .main,
[theme="gray"] .action {
	background: #F2F2F2;
}
[theme="gray"] .mask {
	background: -webkit-gradient(linear,left top, left bottom,from(hsla(0,0%,100%,0)),color-stop(88.32%, #F2F2F2));
	background: linear-gradient(180deg,hsla(0,0%,100%,0),#F2F2F2 88.32%);
}

[theme="dark"].page {
	background: #262628;
	color: rgba(255, 255, 255, 0.85);
}
[theme="dark"] .link {
	color: rgba(255, 255, 255, 0.45);
}
[theme="dark"] .main,
[theme="dark"] .action {
	background: #1C1C1D;
}
[theme="dark"] .action {
	box-shadow: 0 2px 24px rgba(50, 49, 40, 0.5);
}
[theme="dark"] .mask {
	background: -webkit-gradient(linear,left top, left bottom,from(hsla(0,0%,100%,0)),color-stop(88.32%, #1C1C1D));
	background: linear-gradient(180deg,hsla(0,0%,100%,0),#1C1C1D 88.32%);
}
[theme="dark"] .themeCircle:global(.active) {
	border-color: rgba(255, 255, 255, 0.85);
}
[theme="dark"] .pagination :global(.MuiButton-root) {
	background: rgba(255, 255, 255, 0.12);
}
[theme="dark"] .pagination :global(.MuiButton-root):hover {
	background: rgba(255, 255, 255, 0.08);
}

@keyframes showFsCircle {
	0% {
		width: 0;
	}
	50% {
		width: 60px;
	}
	100% {
		width: 120px;
	}
}

@keyframes hideFsCircle {
	0% {
		display: flex;
		width: 120px;
	}
	50% {
		display: flex;
		width: 60px;
	}
	99% {
		display: flex;
		width: 1px;
	}
	100% {
		display: none;
		width: 0;
	}
}
