
.drawerContent {
	width: 520px;
	/* background: var(--bg-color-default); */
}
.storyInfo {
	padding: 12px 40px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid var(--border-color-default);
}
.storyInfo  .storyCover {
	width: 80px;
	height: 107px;
	margin-right: 30px;
	vertical-align: top;
	border-radius: 4px;
}
.storyInfo  .storyTitle {
	font-size: 20px;
	font-weight: 400;
	line-height: 28px;
	margin-bottom: 12px;
}
.storyInfo  .storyAuthor {
	font-size: 12px;
	line-height: 17px;
	color: var(--text-font-color-grey);
}
.sorterContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 20px 24px;
	border-bottom: 1px solid var(--border-color-default);
}
.sorter {
	-webkit-transition: -webkit-transform .2s;
	transition: -webkit-transform .2s;
	transition: transform .2s;
	transition: transform .2s, -webkit-transform .2s;
	cursor: pointer;
}
.sorter:global(.desc) {
	-webkit-transform: scaleY(-1) translateY(-3px);
	transform: scaleY(-1) translateY(-3px);
}
.item {
	width: 100%;
}
.item.selected {
	color: var(--primary-color);
}
.item.locked {
	color: var(--font-color-grey);
}
.itemInner {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 20px 24px;
	line-height: 24px;
	font-size: 16px;
	border-bottom: 1px solid var(--border-color-default);
}
.itemContent {
	flex: 1;
	min-width: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.itemIndex {
	display: block;
	width: 30px;
}