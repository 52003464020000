.commentList {
	background: #fff;
	position: relative;
}
.commentHead {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 12px;
}
.commentCount {
	line-height: 20px;
	margin-left: 16px;
	color: var(--font-color-grey);
}
