.countsItem {
	font-size: 12px;
	line-height: 2;
	display: inline-block;
	margin-right: 15px;
	color: var(--font-color-grey);
}
.countsItem svg {
	vertical-align: top;
	margin-right: 3px;
	display: inline-block;
}